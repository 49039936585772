import { paths } from "./schema";

import { useGlobalRouter } from "../router/globalRouterInstance";
import { apiCallWrap } from "@/utilities/errorHandler";
import { FormKitNode } from "@formkit/core";

const { gRouter: router } = useGlobalRouter();

export type Path = keyof paths;
export type PathMethod<T extends Path> = Extract<keyof paths[T], string>;

export type ErrorType = {
    message: string;
    code: number;
    payload: any;
};

export type RequestParams<
    P extends Path,
    M extends PathMethod<P>
> = paths[P][M] extends { parameters: { query: any } }
    ? paths[P][M]["parameters"]["query"]
    : undefined | null;

export type RequestBody<
    P extends Path,
    M extends PathMethod<P>
> = paths[P][M] extends {
    requestBody: { content: { "application/json": any } };
}
    ? paths[P][M]["requestBody"]["content"]["application/json"]
    : paths[P][M] extends {
          requestBody: { content: { "multipart/form-data": any } };
      }
    ? paths[P][M]["requestBody"]["content"]["multipart/form-data"] & {
          excludeContentType: true;
      }
    : undefined | null;

export type ResponseType<
    P extends Path,
    M extends PathMethod<P>
> = paths[P][M] extends {
    responses: {
        200: { content: { "application/json": { [x: string]: any } } };
    };
}
    ? paths[P][M]["responses"][200]["content"]["application/json"]
    : undefined;

export const apiCall = async <P extends Path, M extends PathMethod<P>>(
    url: P,
    method: M,
    params: RequestParams<P, M>,
    body: RequestBody<P, M>
): Promise<Required<ResponseType<P, M>>> => {
    try {
        if (typeof method !== "string")
            throw new Error("Method must be a string");

        const dbURL = `${import.meta.env.VITE_DATABASE_URL}:${
            import.meta.env.VITE_X_BRANCH
        }`;
        let requestUrl: string = dbURL + url;
        const xBranch: string = import.meta.env.VITE_X_BRANCH;
        const xDatasource: string = import.meta.env.VITE_X_DATASOURCE;
        const xTag: string = import.meta.env.VITE_X_TAG;
        const token = sessionStorage.getItem("apiToken");
        const headers = new Headers();

        if (!body?.excludeContentType) {
            headers.append("Content-Type", "application/json");
        }
        if (xBranch) headers.append("X-Branch", `${xBranch}`);
        if (xDatasource) headers.append("X-Data-Source", `${xDatasource}`);
        if (xTag) headers.append("X-App-build", `${xTag}`);
        if (token) headers.append("Authorization", `Bearer ${token}`);

        if (params && params !== null && Object.keys(params).length) {
            const query = new URLSearchParams(params);
            requestUrl += `?${query}`;
        }

        let requestInit: RequestInit = {
            headers,
            method: method.toUpperCase(),
        };

        if (body && body !== null && Object.keys(body).length) {
            if (!body.excludeContentType) {
                requestInit.body = JSON.stringify(body);
            } else {
                const { excludeContentType, ...rest } = body;
                const formBody = new FormData();
                Object.entries(rest).forEach(([key, value]) => {
                    if (value instanceof File) {
                        formBody.append(key, value, value.name);
                    } else {
                        formBody.append(key, value);
                    }
                });
                requestInit.body = formBody;
            }
        }

        const response = await fetch(requestUrl, requestInit);
        const responseBody = await response.json();

        // const isDev = import.meta.env.VITE_ENVIRONMENT === "dev";

        //NOTE - If the response status is 400, 403, 404, or 500, the response body should be thrown as an error.
        if ([400, 403, 404, 500].includes(response.status)) {
            throw responseBody;
        }

        //NOTE - 401 is a special case. If the token is expired, the user should be redirected to the login page.
        // If routing instance is not available fallback is to throw the response body as an error.
        if (response.status === 401) {
            if (responseBody && responseBody.code) {
                if (responseBody.code === "ERROR_CODE_UNAUTHORIZED") {
                    if (
                        router.value &&
                        //@ts-expect-error
                        router.value.currentRoute.name !== "login"
                    ) {
                        router.value.push({ name: "login" });
                    }
                }
                throw responseBody;
            }
        }

        if (!response.ok) throw new Error(response.statusText);

        return responseBody;
    } catch (error) {
        throw error as ErrorType;
    }
};

//NOTE - This function is used to set up a form submit function that calls the apiCall function and handles the response.
export function setupFormSubmit<T extends Path, U extends PathMethod<T>>(
    path: T,
    method: U,
    cb: (a?: Required<ResponseType<T, U>>) => any
) {
    return async (formData: RequestBody<T, U>, node: FormKitNode) => {
        //NOTE - apiCallWrap handles possible errors and sets the errors on the form node.
        await apiCallWrap(async () => {
            const _res = await apiCall(path, method, null, formData);
            return _res ? cb(_res) : null;
        }, node);
    };
}

export default apiCall;
// async function verify2FA(formValue: { '2FA_input': string }, node: FormKitNode) {
//     await apiCallWrap(async () => {
//         await apiCall('/auth/login/2FA', 'post', null, formValue) ? emit('success') : null;
//     }, node);
// }
