import { apiCall} from '@/api/apiCall'
import { apiCallWrap } from "./errorHandler";
import { useAuthStore } from "@/stores/authStore";
import { useDeclarationUnit } from "@/stores/declarationUnit";

export function useAuthUtils() {
    const authStore = useAuthStore();
    const declarationUnit = useDeclarationUnit();

    function logOut() {
        
        apiCallWrap(async () => {
            await apiCall('/auth/logout', 'get', null, null)
            authStore.$reset();
            declarationUnit.$reset();
        })
    }

    return {
        logOut,
    };
}
