import { defineStore } from "pinia";
import { ref, watch } from "vue";
import router from "@/router";
import { useSessionStorage } from "@vueuse/core";

export const useAuthStore = defineStore("auth", () => {
    const token = useSessionStorage("apiToken", ref(""));
    const user = useSessionStorage(
        "user",
        ref<Record<string, any>>({
            email: "",
        })
    );

    watch(
        () => token.value,
        (value) => {
            if (!value) {
                router.push({ name: "login" });
            }
        }
    );

    function $reset() {
        token.value = "";
        user.value = {};
    }

    return {
        token,
        user,
        $reset,
    };
});
