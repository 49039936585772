import { createRouter, createWebHistory } from "vue-router";
import { useAuthStore } from "@/stores/authStore";
import useLocale from "@/stores/locale";
import { localekeys } from "@/assets/i18n";

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: "/users",
            component: () => import("@layouts/UsersLayout.vue"),
            name: "users",
        },
        {
            path: "/declaration",
            component: () => import("@layouts/DeclarationLayout.vue"),
            children: [
                {
                    path: ":id/confirm",
                    name: "confirmcompanies",
                    component: () =>
                        import("@views/DeclarationFlow/ConfirmCompanies.vue"),
                },
                {
                    path: ":id/import",
                    name: "declareimport",
                    component: () =>
                        import("@views/DeclarationFlow/DeclareImport.vue"),
                },
                {
                    path: ":id/export",
                    name: "declareexport",
                    component: () =>
                        import("@views/DeclarationFlow/DeclareExport.vue"),
                },
                {
                    path: ":id/summary",
                    name: "declarationsummary",
                    component: () =>
                        import("@views/DeclarationFlow/DeclarationSummary.vue"),
                },
            ],
        },
        {
            path: "/error",
            component: () => import("@layouts/BlankLayout.vue"),
            children: [
                {
                    path: "",
                    name: "error",
                    component: () => import("@views/ErrorView.vue"),
                },
            ],
        },
        {
            path: "/login",
            component: () => import("@layouts/BlankLayout.vue"),
            children: [
                {
                    path: "",
                    name: "login",
                    component: () => import("@views/LoginView.vue"),
                },
            ],
        },
        {
            path: "/password-forgot",
            component: () => import("@layouts/BlankLayout.vue"),
            children: [
                {
                    path: "",
                    name: "pwforgot",
                    component: () => import("@views/RequestPasswordReset.vue"),
                },
                {
                    path: ":hash",
                    name: "resetpassword",
                    component: () => import("@views/ResetPassword.vue"),
                },
            ],
        },
        {
            path: "/invitation",
            component: () => import("@layouts/BlankLayout.vue"),
            children: [
                {
                    path: ":hash",
                    name: "invitation",
                    component: () => import("@views/ResetPassword.vue"),
                },
            ],
        },
        {
            path: "/",
            component: () => import("@layouts/MainLayout.vue"),
            children: [
                {
                    path: "",
                    name: "selectDeclarationUnit",
                    component: () =>
                        import("@views/SelectDeclarationUnitView.vue"),
                },
                {
                    path: "introduction",
                    name: "intro",
                    component: () => import("@views/IntroView.vue"),
                },
                {
                    path: "documents",
                    name: "documents",
                    component: () => import("@views/DocumentsView.vue"),
                },
                {
                    path: "declarations",
                    name: "declarations",
                    component: () => import("@views/DeclarationsView.vue"),
                },
                {
                    path: "members",
                    name: "members",
                    component: () => import("@views/MembersView.vue"),
                },
                {
                    path: "finance",
                    name: "finance",
                    component: () => import("@views/FinanceView.vue"),
                },
            ],
        },
    ],
});

router.beforeEach((to, _, next) => {
    const authStore = useAuthStore();
    const { locale, setLocale } = useLocale();
    if (
        !["login", "invitation", "pwforgot", "resetpassword"].includes(
            typeof to.name === "string" ? to.name : ""
        ) &&
        (!authStore.token || !authStore.user.id || !authStore.user.active)
    ) {
        if (authStore.token && authStore.user?.email) {
            next({ name: "login", query: { step: "2fa" } });
            return;
        }
        next({ name: "login" });
        return;
    } else {
        //NOTE - This allows outside actors to change the language by appending ?lang=xx to the URL
        if (
            locale.value &&
            to.query.lang &&
            to.query.lang !== locale.value &&
            localekeys.has(to.query.lang as any)
        ) {
            setLocale(to.query.lang as any);
        }
        next();
    }
});

export default router;
