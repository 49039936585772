export type LocalazyLanguage = {
    language: string;
    region: string;
    script: string;
    isRtl: boolean;
    localizedName: string;
    name: string;
    pluralType: (n: number) => "zero" | "one" | "two" | "many" | "few" | "other";
};
export type LocalazyFile = {
    cdnHash: string;
    file: string;
    path: string;
    library: string;
    module: string;
    buildType: string;
    productFlavors: string[];
    cdnFiles: { [lang:string]: string };
};
export type LocalazyMetadata = {
    projectUrl: string;
    baseLocale: string;
    languages: LocalazyLanguage[];
    files: LocalazyFile[];
};
     
const localazyMetadata: LocalazyMetadata = {
  projectUrl: "https://localazy.com/p/textool",
  baseLocale: "nl",
  languages: [
    {
      language: "en",
      region: "",
      script: "",
      isRtl: false,
      name: "English",
      localizedName: "English",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    },
    {
      language: "nl",
      region: "",
      script: "",
      isRtl: false,
      name: "Dutch",
      localizedName: "Nederlands",
      pluralType: (n) => { return (n===1) ? "one" : "other"; }
    }
  ],
  files: [
    {
      cdnHash: "fe51da3a6b2a57ca0ac63abcfef62dd124e5fae1",
      file: "webapp.json",
      path: "",
      library: "",
      module: "",
      buildType: "",
      productFlavors: [],
      cdnFiles: {
        "en": "https://delivery.localazy.com/_a7576296263285930371ead6ef59/_e0/fe51da3a6b2a57ca0ac63abcfef62dd124e5fae1/en/webapp.json",
        "nl": "https://delivery.localazy.com/_a7576296263285930371ead6ef59/_e0/fe51da3a6b2a57ca0ac63abcfef62dd124e5fae1/nl/webapp.json"
      }
    },
    {
      cdnHash: "153963eb10928f74a176dbb49009755fa15eb271",
      file: "content.json",
      path: "",
      library: "",
      module: "",
      buildType: "",
      productFlavors: [],
      cdnFiles: {
        "en": "https://delivery.localazy.com/_a7576296263285930371ead6ef59/_e0/153963eb10928f74a176dbb49009755fa15eb271/en/content.json",
        "nl": "https://delivery.localazy.com/_a7576296263285930371ead6ef59/_e0/153963eb10928f74a176dbb49009755fa15eb271/nl/content.json"
      }
    }
  ]
};

export default localazyMetadata;