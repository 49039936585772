import { defineStore } from "pinia";
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { defaultLocale } from "@/assets/i18n";
import useLocale from "./locale";

export const useGeneralStore = defineStore("general", () => {
    const { locale: i18nLocale } = useI18n({ useScope: "global" });
    let config = ref<{ locale: string }>();

    //NOTE - Locale was split out so it can be used in the router beforeEach hook. The settings above don't initialize outside script setup. A reference lives here so it can be watched and updated.
    const { locale } = useLocale();

    watch(
        locale,
        (newLocale) => {
            i18nLocale.value = newLocale;
            if (config.value) {
                config.value.locale = newLocale;
            }
        },
        { immediate: true }
    );

    function $reset() {
        locale.value = defaultLocale;
    }

    return {
        locale,
        $reset,
        config,
    };
});
