<script setup lang="ts">
    const props = defineProps<{
        label?: string,
    }>()
</script>

<template>
    <span class="c-pill">
        <slot name="default"
              :label="props.label">{{ props.label }}</slot>
    </span>
</template>

<style lang="scss" scoped>
.c-pill {
    padding: 0.25rem 0.5rem;
    border: 1px solid var(--color-neutral);
    border-radius: 0.25rem;
}
</style>
