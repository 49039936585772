import { ref, Ref } from "vue";
import { defaultLocale } from "@/assets/i18n";
import { useLocalStorage } from "@vueuse/core";
import { LocaleKey } from "@/assets/i18n";

const locale = useLocalStorage(
    "locale",
    ref<LocaleKey>(defaultLocale)
) as Ref<LocaleKey>;

function setLocale(newLocale: LocaleKey) {
    locale.value = newLocale;
}

export default function useLocale() {
    return {
        locale,
        setLocale,
    };
}
