<script setup lang="ts">

    const datasource = import.meta.env.VITE_X_DATASOURCE;
    const branch = import.meta.env.VITE_X_BRANCH;
    const tag = import.meta.env.VITE_X_TAG;

</script>

<template>
    <ul class="c-versiontag u-flex u-gap-2">
        <li>Datasource: {{ datasource }}</li>
        <li>Branch: {{ branch }}</li>
        <li>Version tag: {{ tag }}</li>
    </ul>
</template>

<style scoped>
ul {
    font-family: 'Courier New', Courier, monospace;
}
</style>