import { ref, watch, computed } from "vue";
import { defineStore } from "pinia";
import {
    useSessionStorage,
    StorageSerializers,
    RemovableRef,
} from "@vueuse/core";
import { useRouter } from "vue-router";
import { apiCall, ResponseType } from "@/api/apiCall";
import { useAuthStore } from "./authStore";
import useLocale from "./locale";

import {
    type DeclarationLine,
    type DeclarationUnit,
    type Declaration,
    type DUDownload,
    type Company,
} from "@/api/apiTypes";

import { apiCallWrap } from "@/utilities/errorHandler";

export type Invoice = PickRequired<
    ResponseType<"/declarationunit/1/invoice", "get">[0],
    "id"
>;

export const useDeclarationUnit = defineStore("declarationUnit", () => {
    const { locale } = useLocale();
    const authStore = useAuthStore();

    const router = useRouter();

    const declarationUnits = useSessionStorage(
        "declarationUnits",
        ref<DeclarationUnit[]>([])
    );

    const declarations = ref<Declaration[]>([]);

    const declarationLines = ref<DeclarationLine[]>([]);

    const downloads = ref<DUDownload[]>([]);
    const invoices = ref<Invoice[]>([]);
    const companies = ref<Company[]>([]);
    const activeDUID: RemovableRef<number | undefined> = useSessionStorage(
        "activeDUID",
        ref(),
        {
            serializer: StorageSerializers.number,
        }
    );

    const refreshPending = ref(false);

    const activeDeclarationUnit = computed(() => {
        return declarationUnits.value.find((du) => du.id === activeDUID.value);
    });

    watch(
        activeDUID,
        async (newValue) => {
            if (authStore.token && !newValue) {
                router.push({ name: "selectDeclarationUnit" });
            } else if (authStore.token && newValue) {
                refreshPending.value = true;
                await refreshAllInvoices();
                await refreshDownloads();
                await refreshAllDeclarations();
                await refreshAllCompanies();
                refreshPending.value = false;
            }
        },
        { immediate: true }
    );

    function refreshAllCompanies() {
        return apiCallWrap(async () => {
            if (!activeDUID.value) throw new Error();
            const response = await apiCall(
                `/declarationunit/${activeDUID.value}/company`,
                "get",
                null,
                null
            );
            companies.value = response as Company[];
        });
    }

    function refreshDownloads() {
        return apiCallWrap(async () => {
            if (!activeDUID.value) throw new Error();
            const response = await apiCall(
                `/declarationunit/${activeDUID.value}/downloads`,
                "post",
                null,
                { lang: locale.value }
            );
            downloads.value = response as DUDownload[];
        });
    }

    function refreshAllInvoices() {
        return apiCallWrap(async () => {
            if (!activeDUID.value) throw new Error();
            const response = await apiCall(
                `/declarationunit/${activeDUID.value}/invoice`,
                "get",
                null,
                null
            );
            invoices.value = response as Invoice[];
        });
    }

    function refreshAllDeclarations() {
        return apiCallWrap(async () => {
            if (!activeDUID.value) throw new Error();
            const response = await apiCall(
                `/declarationunit/${activeDUID.value}/declaration`,
                "get",
                null,
                null
            );
            declarations.value = response as unknown as Declaration[];
        });
    }

    function $reset() {
        declarationUnits.value = [];
        activeDUID.value = undefined;
    }

    return {
        declarationUnits,
        declarations,
        declarationLines,
        invoices,
        downloads,
        companies,
        activeDUID,
        activeDeclarationUnit,
        refreshPending,
        refreshAllDeclarations,
        refreshAllInvoices,
        refreshDownloads,
        $reset,
    };
});
