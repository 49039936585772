import { createApp } from "vue";
import App from "./App.vue";
//NOTE - These are global components that are used throughout the application.
import LoadingSpinner from "./components/atoms/LoadingSpinner.vue";
import ArrowLink from "./components/atoms/ArrowLink.vue";
import PillComponent from "./components/atoms/PillComponent.vue";
import ActionButton from "./components/atoms/ActionButton.vue";
import FaIcon from "./components/atoms/FaIcon.vue";
//NOTE - Toastification is a library for displaying toasts in Vue. It is used to display success and error messages to the user.
import Toast, { POSITION, PluginOptions } from "vue-toastification";
import "vue-toastification/dist/index.css";
//NOTE - Pinia is a state management library for Vue. It is used to manage the state of the application.
import { createPinia } from "pinia";
//NOTE - Router is a library for managing the routes of the application. It is used to manage the routes of the application.
import router from "./router";
import "./assets/scss/style.scss";
//NOTE - Sentry is a monitoring tool that is used to monitor the application for errors and performance issues.
import * as Sentry from "@sentry/vue";

//NOTE - FormKit imports
import { createInput, defaultConfig, plugin } from "@formkit/vue";
import { createFloatingLabelsPlugin } from "@formkit/addons";
import "@formkit/addons/css/floatingLabels";
import { nl } from "@formkit/i18n";
import { genesisIcons } from "@formkit/icons";
import OneTimePassword from "@/components/forms/inputs/OneTimePassword.vue";
import KvKInput from "@/components/forms/inputs/KvKInput.vue";

//i18n imports
import { I18nOptions, createI18n } from "vue-i18n";
import { locales, defaultLocale as locale } from "@/assets/i18n";

const app = createApp(App);
const pinia = createPinia();

const i18nOptions: I18nOptions = {
    legacy: false,
    locale,
    fallbackLocale: "nl",
    messages: locales.value,
    warnHtmlMessage: false,
    missingWarn: false,
    silentFallbackWarn: true,
    silentTranslationWarn: true,
};
const i18n = createI18n<false, typeof i18nOptions>(i18nOptions);

app.use(
    plugin,
    defaultConfig({
        inputs: {
            otp: createInput(OneTimePassword, { props: ["digits"] }),
            kvk: createInput(KvKInput),
        },
        plugins: [createFloatingLabelsPlugin()],
        theme: "genesis",
        icons: {
            ...genesisIcons,
        },
        locales: { nl },
        locale,
    })
);

const toastOptions: PluginOptions = {
    position: POSITION.BOTTOM_CENTER,
    closeOnClick: true,
    pauseOnHover: true,
};

Sentry.init({
    app,
    dsn: "https://6bd90e0d96f2c09681ced44576ba5a36@o4506744415846400.ingest.sentry.io/4506745289637888",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    release: import.meta.env.VITE_X_TAG,
    environment: import.meta.env.VITE_X_BRANCH,
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        "localhost",
        /^https:\/\/xano\.esperantoxl\.nl/,
        /^https:\/\/backend\.stichtingupvtextiel\.nl/,
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.component("LoadingSpinner", LoadingSpinner)
    .component("ArrowLink", ArrowLink)
    .component("ActionButton", ActionButton)
    .component("PillComponent", PillComponent)
    .component("FaIcon", FaIcon);
app.use(Toast, toastOptions);
app.use(i18n);
app.use(pinia);
app.use(router);
app.mount("#app");
