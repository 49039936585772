<template>
    <p>
        <i class="fa-solid fa-sharp fa-spinner-scale fa-spin"></i>
    </p>
</template>

<style lang="scss" scoped>
i {
    color: var(--primary-color);
    font-size: 4rem;
}
</style>