import localazyMetadata from "@/assets/i18n/meta";
import { type LocaleKey } from "@/assets/i18n";
import { locales } from "@/assets/i18n";

async function getLocaleFromCDN(
    locale: LocaleKey,
    metadata: any | null = null
) {
    if (metadata) {
        const _file: any = Object.values(metadata.files).find(
            (file: any) => file.file === "webapp.json"
        );

        if (!_file) {
            throw new Error("File not found");
        }

        const uri = _file.locales.find(
            (obj: any) => obj.language === locale
        ).uri;
        const res = await fetch(`https://delivery.localazy.com${uri}`);
        const obj = await res.json();

        if (!obj) return;

        locales.value[locale] = Object.assign({}, locales.value[locale], obj);
    }
    if (!metadata) {
        const _file = localazyMetadata.files.find(
            (file) => file.file === "webapp.json"
        );

        if (!_file) {
            throw new Error("File not found");
        }

        const uri = _file.cdnFiles[locale];
        const res = await fetch(uri);
        const obj = await res.json();

        /*
         * Overwrite only those keys that are present in the CDN.
         * This is to ensure that the fallbacks are not overwritten.
         */
        locales.value[locale] = Object.assign({}, locales.value[locale], obj);
    }
}

export default {
    getLocaleFromCDN,
};
