import { ref } from "vue";
import nl from "./nl/webapp.json";
import en from "./en/webapp.json";

export const defaultLocale = "nl";

export const locales = ref({
    en,
    nl,
});

export const localekeys = new Set(
    Object.keys(locales.value) as Array<keyof typeof locales.value>
);

export type MessageSchema = typeof locales.value.nl;

export type LocaleKey = keyof typeof locales.value;

export default locales;
