// import { useRouter } from "vue-router";
import { FormKitNode } from "@formkit/core";
import { useToast } from "vue-toastification";
import { useGlobalt } from "@/assets/i18n/globalt";

// import { useI18n } from "vue-i18n";

const { globalt: t } = useGlobalt();
// const { t} = useI18n();
const toast = useToast();
// const router = useRouter();

export async function apiCallWrap(
    fn: () => Promise<any>,
    node?: FormKitNode,
    finalFn?: () => void
) {
    if (node) node.clearErrors();
    return fn()
        .catch((error) => {
            return handleError(error, node);
        })
        .finally(() => finalFn && finalFn());
}

export function handleError(error: unknown, node?: FormKitNode) {
    let _msg: string;
    if (typeof error === "string") {
        _msg = error;
    } else if (
        error instanceof Error ||
        (typeof error === "object" && error !== null)
    ) {
        _msg = handleObjectError(error);
    }

    _msg ??= "error.generic";

    if (node) {
        node.setErrors([t.value(_msg)]);
        return;
    }
    const timeout = biggestNumber(wordCount(t.value(_msg)) * 500, 5000);
    toast.error(t.value(_msg), { timeout });
}

// statement to return the biggest number
function biggestNumber(a: number, b: number) {
    return a > b ? a : b;
}

//give me a function takes a string and returns me the wordcount
function wordCount(str: string) {
    return str.split(" ").length;
}

function isTranslated(str: string) {
    return t.value(str) !== str;
}
//error.generic

function handleObjectError(error: Record<string, any>) {
    console.log("error", error);
    if (error.message && isTranslated(error.message)) {
        return error.message;
    }

    if (error.code && isTranslated(error.code)) {
        return error.code;
    }

    return "error.generic";
}
